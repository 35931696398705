import { useEffect, useRef } from 'react';
import Vimeo from '@vimeo/player';

export default function VimeoPlayer(props) {
    const iframe = useRef(null);
    const { embed, className, ...options } = props;

    useEffect(() => {
        const player = new Vimeo(iframe.current, options);
    }, []);
    
    return <iframe className={className} ref={iframe} src={embed} frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
}