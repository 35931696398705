import logo from './logo.svg';
import './App.css';
import VimeoPlayer from './vimeoplayer';

function App() {
  const vimeo_url = "https://vimeo.com/event/3543069"
  const youtube_url = "https://youtube.com/live/Xkj-3kS6ysk?feature=share"
  
  return (
    <>
      <div className="App">
        <header className="App-header">
          <div className="container mx-auto lg:px-32">
            <p className="p-4 font-semibold">Emily Morgan - 1977-2023</p>
            <VimeoPlayer embed={`${vimeo_url}/embed`} responsive={true} className="aspect-video w-full"></VimeoPlayer>
            <p className="p-4 font-light">Emily's family have asked in lieu of flowers, you may make a donation via Sullivan Funerals to St Peter and St James Hospice which cared for Emily so well - <a href="https://sullivanfunerals.co.uk/donations" className="no-underline hover:underline decoration-sky-500">https://sullivanfunerals.co.uk/donations</a></p>
            <p className="p-4 font-light">If you are having issues accessing the stream, <a className="no-underline hover:underline decoration-sky-500" href={youtube_url}>please click here.</a></p>
          </div>
        </header>
      </div>
    </>
  );
}

export default App;
